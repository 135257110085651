let Vue = window.Vue;
let pageData = window.pageData() || {};
Vue.component('section-head', {
    template: `
     <div class="container-head-common">
        <img :src="iconUrl" class="head-title-icon">
        <span class="head-text">{{title}}</span>
        <img src="/static/images/homePage/head_icon.png" class="head-icon">
        <div class="line"></div>
        <template v-if="flagMore">
            <span class="head-more">更多</span>
            <img :src="moreIconUrl" class="head-more-icon">
        </template>
        
     </div>
    `,
    props: {
        icon: {
            type: String
        },
        title: {
            type: String,
            required: true
        },
        flagMore: {
            type: Boolean,
            default: true
        },
        moreIcon: {
            type: String
        }
    },
    data () {
      return {}
    },
    computed: {
        iconUrl () {
            return `/static/images/homePage/${this.icon}.png`
        },
        moreIconUrl () {
            return `/static/images/homePage/${this.moreIcon}.png`
        }
    }
})
new Vue({
    el: '#content',
    data () {
        return {
            pageData,
            trackList: [
                {
                    icon: 'train',
                    name: '培训机构报名'
                },
                {
                    icon: 'platform',
                    name: '平台咨询'
                },
                {
                    icon: 'stu_platform',
                    name: '学员平台受理'
                },
                {
                    icon: 'stu_wechat',
                    name: '学员微端'
                }
            ],
            specialtyList: [
                {
                    cover: 'specialty_item',
                    name: '建筑工程'
                },
                {
                    cover: '',
                    name: '机电工程'
                },
                {
                    cover: '',
                    name: '市政工程'
                },
                {
                    cover: '',
                    name: '公路工程'
                },
                {
                    cover: '',
                    name: '水利水电工程'
                }
            ],
            teacherList: [
                {
                    cover: 'specialty_item',
                    name: '朱教授',
                    technical: '华东师范大学 教授',
                    introduce: '中国人民大学党委员、副校长中国人民大学党委员、副校长中国人民大学党委员、副校长中国人民大学'
                },
                {
                    cover: 'specialty_item',
                    name: '朱教授',
                    technical: '华东师范大学 教授',
                    introduce: '中国人民大学党委员、副校长中国人民大学党委员、副校长中国人民大学党委员、副校长中国人民大学'
                },
                {
                    cover: 'specialty_item',
                    name: '朱教授',
                    technical: '华东师范大学 教授',
                    introduce: '中国人民大学党委员、副校长中国人民大学党委员、副校长中国人民大学党委员、副校长中国人民大学'
                },
                {
                    cover: 'specialty_item',
                    name: '朱教授',
                    technical: '华东师范大学 教授',
                    introduce: '中国人民大学党委员、副校长中国人民大学党委员、副校长中国人民大学党委员、副校长中国人民大学'
                }
            ],
            unitList: [
                {
                    name: '上海市绿色建筑协会'
                },
                {
                    name: '上海市绿色建筑协会'
                },
                {
                    name: '上海建设管理职业技术学院'
                },
                {
                    name: '上海市绿色建筑协会'
                },
                {
                    name: '上海市室内装饰行业协会装配式内装产业专业委员会'
                },
                {
                    name: '上海市绿色建筑协会'
                }
            ]
        };
    },
    mounted () {
        console.log(pageData, 'ppppp')
    }
});